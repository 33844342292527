<template>
  <Browse
    :columns="[
      'name',
      'customer_type',
      'phone',
      'other_phone',
      'mail',
      'instagram',
      'facebook',
    ]"
    :formats="{
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="customers"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
