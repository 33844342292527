var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'name',
    'customer_type',
    'phone',
    'other_phone',
    'mail',
    'instagram',
    'facebook' ],"formats":{
  },"itemsPerPage":"10","searchOnStart":true,"table":"customers"}})}
var staticRenderFns = []

export { render, staticRenderFns }